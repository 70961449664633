import Cookies from "js-cookie";
import { profileAPI } from "../api/profile-api";
import {
  CURRENT_PROFILE_ERROR,
  UPDATE_PROFILE_ERROR,
} from "../reducers/types";
import {
  setProfileAC,
  setErrorAC,
  setLoadingAC,
  updateProfileAC,
} from "./actions";
import { authThunks } from "./auth-thunks";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

//profile thunks
export const profileThunks = {
  getProfileTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.getProfile();
      if (response.status === 200) {
        dispatch(setProfileAC(response.data));
      }
      dispatch(setErrorAC({ type: CURRENT_PROFILE_ERROR, message: "" }));
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(authThunks.logoutTC());
      }
      dispatch(
        setErrorAC({
          type: CURRENT_PROFILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  updateProfileTC: (profileData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const newProfileData = { ...profileData };
      const response = await profileAPI.updateProfile(newProfileData);
      if (response.status === 200) {
        dispatch(updateProfileAC(profileData));
      }
      dispatch(setErrorAC({ type: UPDATE_PROFILE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: UPDATE_PROFILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  upgradeSimulatorTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.upgradeSimulator();
      if (response.status === 200) {
        const domain =
          window?.location.hostname === "dev.portal.zimtra.at-hub.com"
            ? ".zimtra.at-hub.com"
            : window?.location.hostname === "cloud-stage.portal.zimtra.com"
              ? ".zimtra.com"
              : ".zimtra.ky";
        Cookies.remove("portalToken", {
          domain,
          secure: true,
          sameSite: "None",
        });
        Cookies.set("onboardingToken", response.data.onboardingToken, {
          expires: 30,
          domain,
          secure: true,
          sameSite: "None",
        });
        utilityFunctions.redirectToOnboarding();
      }
    } catch (error) {
      dispatch(
        setErrorAC({
          type: UPDATE_PROFILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
